import { React } from 'react'
import { Helmet } from 'react-helmet'
import MainHeader from '../../components/MainHeader/MainHeader.js'
import MainFooter from '../../components/MainFooter/MainFooter.js'

import logo_main from '../../assets/pictures/logos/naturheilkundekonefal.webp'

import { lP, oP, sT } from '../../main.js'

import '../../main.min.css'
import './Impressum.min.css'
import './MImpressum.min.css'


export default function Impressum() {

    return (
        <div className='mainCon' id='mainCon'>
            <img alt='Logo der Naturheilkundepraxis' className='logo_main_header' src={logo_main} onClick={() => lP('/')}></img>

            <MainHeader selectedsite={"impressum"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Impressum</title>
                <meta property="og:title" content="Impressum" />
                <meta property="og:description" content="Hier finden Sie unser Impressum" />
            </Helmet>
            <section className='sec_next' id='sec_next--impressum'>
                <div className='sec_next_cont' id="sec_next--impressum--cont" style={{ wordBreak: "break-word" }}>
                    <h2>Impressum</h2>
                    <p>
                        <b>Angaben gemäß § 5 TMG:</b><br />
                        <br />
                        Praxis für Naturheilkunde | Alena Konefal Heilpraktikerin<br />
                        Heimertinger Str. 39a<br />
                        87700 Memmingen - Steinheim<br />
                        <br />
                        Tel 08331 8338390<br />
                        E-Mail praxis@alena-konefal.de<br />
                        Web alena-konefal.de<br />
                        <br />
                        Vertretungsberechtigt:<br />
                        Alena Konefal<br />
                        <br />
                        <b>Rechtlicher Hinweis:</b><br />
                        <br />
                        Die Zulassung und Erlaubnis zur berufsmäßigen Ausübung der Heilkunde ohne Bestallung wurde in Deutschland erteilt durch die Stadt Memmingen.
                        Zuständige Aufsichtsbehörde: Ordnungsamt/Gesundheitsamt Memmingen
                        <br />
                        <br />
                        <b>Verantwortlich für den Inhalt nach § 55 Abs 2 RStV:</b><br />
                        <br />
                        Alena Konefal<br />
                        <br />
                        <br />
                        <b>Haftung für Inhalte</b><br />
                        <br />
                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br />
                        <br />
                        <br />
                        <b>Haftung für Links</b><br />
                        <br />
                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br />
                        <br />
                        <br />
                        <b>Urheberrecht</b><br />
                        <br />
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br />
                        <br />
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br />
                        <br />
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.<br />
                        <br />
                        <br />

                        <b>Verwendete Icons und Bilder</b><br /><br />
                        Die Bilder wurden teilweise mit KI generiert. Hierfür wurde DALL-E von OpenAI verwendet.

                        <br />
                        <br />
                        <br />
                        <b>Entwickler der Webseite</b><br /><br />
                        ToNetwork UG (haftungsbeschränkt)<br />
                        <a href="https://tonetwork.de">tonetwork.de</a><br />
                        Im Moos 5<br />
                        87749 Hawangen<br />
                        <br />
                        Tel 08332 4908872<br />
                        E-Mail info@tonetwork.de<br />
                    </p>
                    <br /><br />
                    <h2>Datenschutzerklärung</h2>
                    <p>
                        <h3>Datenschutzerklärung nach Art. 13 und 21 DSGVO</h3>
                        <br />
                        <b>Grundlegendes</b><br />
                        <br />
                        Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch die Websitebetreiberin Alena Konefal informieren.<br />
                        <br />
                        Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.<br />
                        <br />
                        Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.<br />
                        <br />
                        <br />
                        <b>Name und Anschrift des für die Verarbeitung Verantwortlichen</b><br />
                        <br />
                        Verantwortliche Stelle im Sinne der DSGVO ist Alena Konefal, Kuttelgasse 16 87700 Memmingen<br />
                        <br />
                        <br />
                        <b>Zugriffsdaten</b><br />
                        <br />
                        Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über Zugriffe auf die Website und speichern diese als „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten werden so protokolliert:<br />
                        <br />
                        <ul>
                            <li>aufgerufene Seiten</li>
                            <li>verwendeter Browser/Betriebssystem/Geräte/Modell</li>
                            <li>Datum u. Uhrzeit des Zugriffs</li>
                            <li>verwendete Suchmaschine oder Link über den man auf die Website gelangt ist</li>
                            <li>anonymisierte IP‐Adresse (nicht zurückverfolgbar)</li>
                            <li>anonyme Sitzungskennung</li>
                            <li>eingestellte Sprache im Browser</li>
                        </ul>
                        <br />
                        Die Server-Logfiles werden für maximal 2 Jahre gespeichert und anschließend gelöscht. Die Speicherung der Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.<br />
                        <br />
                        <br />
                        <b>Reichweitenmessung &amp; Cookies</b><br />
                        <br />
                        Diese Website verwendet Cookies zur pseudonymisierten Reichweitenmessung, die entweder von unserem Server oder dem Server Dritter an den Browser des Nutzers übertragen werden. Bei Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser Website.<br />
                        <br />
                        Gängige Browser bieten die Einstellungsoption, Cookies nicht zuzulassen. Hinweis: Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Sie entsprechende Einstellungen vornehmen.<br />
                        <br />
                        <b><br />
                            Erfassung und Verarbeitung personenbezogener Daten</b><br />
                        <br />
                        Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen.<br />
                        Als personenbezogene Daten gelten sämtliche Informationen, welche dazu dienen, Ihre Person zu bestimmen und welche zu Ihnen zurückverfolgt werden können – also beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.<br />
                        <br />
                        Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu machen. Zur Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei oder der Name Ihres Internet-Providers. Durch die Anonymisierung der Daten sind Rückschlüsse auf Ihre Person nicht möglich.<br />
                        <br />
                        <br />
                        <b>Umgang mit Kontaktdaten</b><br />
                        <br />
                        Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben.<br />
                        <br />
                        <b><br />
                            Webserver Protokolldaten - Nicht personenbezogene Daten, die automatisch erfasst werden</b><br />
                        <br />
                        Bei der Nutzung unserer Internetseiten werden aus organisatorischen und technischen Gründen folgende Daten gespeichert: aufgerufene Seiten, verwendeter Browser / Betriebssystem sowie Gerätemodell, Datum und Uhrzeit des Zugriffs, verwendete Suchmaschine oder Links über den der Besucher auf die Website gelangt ist, anonymisierte IP-Adresse (nicht zurückverfolgbar), anonyme Sitzungskennung, eingestellte Sprache im Browser, Land aus dem der Zugriff erfolgt und eingegebene Suchbegriffe auf der Website, sofern verfügbar.<br />
                        Wir werten diese technischen Daten anonym, lediglich zu statistischen Zwecken und aus Sicherheitsgründen aus, um unseren Internetauftritt weiter optimieren und unsere Internetangebote noch attraktiver gestalten zu können. Diese anonymen Daten werden getrennt von personenbezogenen Informationen auf gesicherten Systemen gespeichert und lassen keine Rückschlüsse auf eine individuelle Person zu. Ihre personenbezogenen Daten und Ihre Privatsphäre sind jederzeit geschützt."<br />
                        <b><br />
                            <br />
                            Dateneinsicht von Dritten<br />
                        </b><br />
                        Unsere Website wird betreut von ToNetwork UG (Im Moos 5 87749 Hawangen , tonetwork.de), welche Einsicht in die erhobenen Daten hat. Der Schutz Ihrer Daten ist uns wichtig.<br />
                        <br />
                        <br />
                        <b>Rechte des Nutzers</b><br />
                        <br />
                        Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.<br />
                        <br />
                        <br />
                        <b>Löschung von Daten</b><br />
                        <br />
                        Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.<br />
                        <br />
                        <br />
                        <b>Widerspruchsrecht</b><br />
                        <br />
                        Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen.<br />
                        <br />
                        Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E-Mail-Adresse: <a href="mailto:datenschutz@liepert.de">datenschutz@liepert.de</a><br />
                        <br />
                        <b><br />
                            Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</b><br />
                        <br />
                        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet, also das Bayerische Landesamt für Datenschutzaufsicht, Ansbach (<a href="http://www.baylda.de">www.baylda.de</a>)<br />
                        <br />
                        <br />


                    </p>
                </div>
            </section>

            <MainFooter />
        </div>
    )
}
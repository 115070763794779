import { React, useState } from 'react'

import { lP, oP, sT } from '../../main.js'

import './MainFooter.min.css'
import './MMainFooter.min.css'
import '../../main.min.css'

import contact_formedthree from '../../assets/pictures/sectionimages/contact_formedthree_ai.webp'

export default function MainFooter(props) {


    return (
        <div className='mainfooterdiv'>

            <div className='maincontact' id='contactme'>
                <div className='maincontact_first'>
                    <h2>Kontakt</h2>
                    <a href='tel:083318338390'>📞  08331/8338390</a>
                    <a href='mailto:praxis@alena-konefal.de'>✉️  praxis@alena-konefal.de</a>
                    <a href='https://maps.app.goo.gl/CuxDsBU98cG3dADX7' target='_blank' style={{ width: "80%", lineBreak: "normal" }}>🚗  Heimertinger Str. 39a, 87700 Memmingen - Steinheim</a>
                </div>
                <div className='maincontact_last'>
                    <img alt='KI generiertes Bild von einem alten Telefon' className='maincontact_img' src={contact_formedthree}></img>
                </div>
            </div>

            <div className='mainfooter'>
                <p>©2025 <b>Praxis für Naturheilkunde Alena Konefal</b></p>
                <div className='footerlinks'>
                    <a href='/impressum'>Impressum & Datenschutz</a>
                </div>
                <p> (Website made with ❤️ by <a href="https://tonetwork.de" style={{ textDecoration: "underline" }} target="_blank">ToNetwork UG</a>)</p>
            </div >
        </div >
    )
}